<template>
    <FormRoot :loading="dataLoading">
        <Field :form.sync="form" type="text" name="name" placeholder="Name" hint="Name of the billing account"></Field>
        <Field
            :form.sync="form"
            type="text"
            name="email"
            placeholder="billing@company.com"
            hint="E-mail for invoices"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="vat"
            placeholder="VAT number"
            hint="VAT number (intra-community)"
        ></Field>
        <Field :form.sync="form" type="text" name="address" placeholder="Address" hint="Billing address"></Field>
        <div class="row">
            <div class="col-6">
                <Field :form.sync="form" type="text" name="zipcode" placeholder="Zipcode" hint="Zipcode"></Field>
            </div>
            <div class="col-6">
                <Field :form.sync="form" type="text" name="city" placeholder="City" hint="City"></Field>
            </div>
        </div>
        <Field
            :form.sync="form"
            type="text"
            name="country_iso"
            placeholder="BE or FR or ..."
            hint="Country (ISO code in lowercase). Example : BE"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="language_iso"
            placeholder="fr or en or ..."
            hint="Language (ISO code in lowercase). Example : fr"
        ></Field>
        <Field
            :form.sync="form"
            type="text"
            name="copy_products_from"
            placeholder="Billing account ID"
            hint="Copy products from another billing account"
        ></Field>
        <Field
            :form.sync="form"
            type="boolean"
            name="direct_invoices_process"
            placeholder="Direct invoices process"
            hint="Allow invoices to be processed before payment is received"
        ></Field>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit">{{
                id ? 'Update' : 'Create'
            }}</Submit>
        </FormActions>
    </FormRoot>
</template>

<script>
    import { FormRoot, Field, FormActions, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'

    export default {
        data() {
            return {
                fields: {
                    name: null,
                    email: null,
                    vat: null,
                    address: null,
                    zipcode: null,
                    city: null,
                    country_iso: 'FR',
                    language_iso: 'fr',
                    copy_products_from: null,
                    direct_invoices_process: false,
                },
                messageSuccess: 'The billing account has been saved',
            }
        },
        mixins: [Forms],
        components: {
            FormRoot,
            FormActions,
            Submit,
            Field,
        },
        methods: {
            loadData() {
                return this.$api.billing.billingAccounts.show(this.id)
            },
            submit() {
                if (this.id) {
                    return this.$api.billing.billingAccounts.update(this.id, this.$data.form.data())
                } else {
                    return this.$api.billing.billingAccounts.create(this.$data.form.data())
                }
            },
        },
    }
</script>
